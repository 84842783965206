import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Payload {
  open: boolean;
}

const initialState: { value: boolean } = {
  value: false,
};

export const createStreamSlice = createSlice({
  initialState,
  name: "createStreamPopup",
  reducers: {
    togglePanel: (state, action: PayloadAction<Payload>) => {
      state.value = action.payload.open;
    },
  },
});
export const { togglePanel } = createStreamSlice.actions;
export default createStreamSlice.reducer;
