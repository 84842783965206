import Link from 'next/link';

const LogoWrapper = ({ href, children }: { href: undefined | string; children: any }) => {
  if (href) {
    return (
      <span className="cursor-pointer">
        <Link href={href}>{children}</Link>
      </span>
    );
  }
  return children;
};

export function Logo({
  fill = "#2563EB",
  className = "h-10",
  href,
  onlyIcon = false,
  ...props
}: {
  fill?: string;
  className?: string;
  href?: undefined | string;
  onlyIcon?: boolean;
}) {
  return (
    <LogoWrapper href={href}>
      <div className="relative">
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 318.3 95.4" className={className} {...props}>
          <path
            fill={fill}
            d="M58,23.7L58,23.7L34.3,0l-6.4,6.4l23.8,23.8l0,0c4.7,4.7,7.2,10.9,7.2,17.6c0,13.8-11.2,25-25,25
      c-13.8,0-25-11.2-25-25c0-6.7,2.6-12.9,7.3-17.7l5.6-5.6l5.8,5.8l-5.6,5.6c-3.2,3.2-4.9,7.4-4.9,11.8c0,9.2,7.5,16.7,16.7,16.7
      c9.2,0,16.7-7.5,16.7-16.7c0-4.5-1.7-8.7-4.9-11.8l-6.4,6.4c1.5,1.5,2.3,3.4,2.3,5.5c0,4.3-3.5,7.7-7.7,7.7c-4.3,0-7.7-3.5-7.7-7.7
      c0-2.1,0.8-4,2.3-5.5l12-12l0,0L22,11.7l-12,12c-6.4,6.4-10,15-10,24c0,18.7,15.3,34,34,34s34-15.3,34-34C68,38.7,64.5,30.2,58,23.7
      z"
          />
          {!onlyIcon && (
            <>
              <path
                fill={fill}
                d="M76.3,47.7c0-10.4,7.8-18.4,18.4-18.4c6.4,0,12.1,3.2,15.2,8.2l-7,4.1c-1.6-2.7-4.6-4.4-8.2-4.4
      c-6.3,0-10.3,4.2-10.3,10.5s4.1,10.5,10.3,10.5c3.6,0,6.7-1.6,8.2-4.4l7,4.1c-3,5-8.7,8.2-15.2,8.2C84.1,66.1,76.3,58.1,76.3,47.7z"
              />
              <path
                fill={fill}
                d="M112.9,47.7c0-10.4,8.3-18.4,18.4-18.4s18.4,8,18.4,18.4s-8.3,18.4-18.4,18.4S112.9,58.1,112.9,47.7z
       M141.7,47.7c0-6.3-4.6-10.5-10.3-10.5c-5.8,0-10.3,4.2-10.3,10.5s4.6,10.5,10.3,10.5C137.2,58.2,141.7,54,141.7,47.7z"
              />
              <path fill={fill} d="M162.4,29.9v35.5h-8.1V29.9H162.4z" />
              <path fill={fill} d="M197.6,29.9v35.5h-6.1l-13.7-19.3v19.3h-8.1V29.9h6.1l13.7,19.3V29.9H197.6z" />
              <path
                fill={fill}
                d="M236.3,47.7c0,10-7.4,17.7-17.2,17.7h-14.2V29.9H219C228.8,29.9,236.3,37.7,236.3,47.7z M228.5,47.7
      c0-6.1-3.9-9.9-9.4-9.9H213v19.9h6.1C224.6,57.6,228.5,53.8,228.5,47.7z"
              />
              <path
                fill={fill}
                d="M254,53.8h-3.9v11.7H242V29.9h14.2c6.7,0,12.2,5.4,12.2,12.2c0,4.4-2.6,8.3-6.5,10.3l7.6,13h-8.7L254,53.8z
       M250.1,46.7h6.1c2.2,0,4.1-2,4.1-4.6c0-2.6-1.8-4.6-4.1-4.6h-6.1V46.7z"
              />
              <path fill={fill} d="M283.8,29.9v35.5h-8.1V29.9H283.8z" />
              <path
                fill={fill}
                d="M318.3,42.1c0,6.7-5.4,12.2-12.4,12.2h-5.3v11.1h-8.1V29.9h13.4C312.9,29.9,318.3,35.4,318.3,42.1z M310.2,42.1
      c0-2.6-1.8-4.6-4.3-4.6h-5.3v9.1h5.3C308.4,46.7,310.2,44.7,310.2,42.1z"
              />
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </>
          )}
        </svg>
        {/* <span className="absolute right-0 text-[10px] font-bold uppercase text-white opacity-50">alpha</span> */}
      </div>
    </LogoWrapper>
  );
}
