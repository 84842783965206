import { Address } from "@multiversx/sdk-core/out";
import axios from "axios";
import BigNumber from "bignumber.js";

import { network } from "../config";
import { IStream, IStreamApiResponse } from "../types/Api";
import { IStreamListingItem } from "../types/Database";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function getShortAddress(address: string, chars = 4): string {
  return address.substring(0, chars) + "..." + address.substring(address.length - chars);
}

export async function getHerotag(address: string): Promise<string | undefined> {
  const { data } = await axios.get(`${network.apiAddress}/accounts/${address}`);
  return data?.username;
}

export async function getAvatar(address: string): Promise<string | undefined> {
  try {
    const avatarUrl = `https://id.maiar.com/users/photos/profile/${address}`;
    const _res = await axios.get(avatarUrl);
    return avatarUrl;
  } catch (e) {
    return undefined;
  }
}

export function getProgress(stream: IStream | IStreamListingItem): number {
  if (!stream) return 0;
  if (new Date() < stream.start_time) return 0;
  const totalTime = stream.end_time.getTime() - stream.start_time.getTime();
  // @ts-ignore
  if (stream?.cancelled?.created_at && stream.cancelled.created_at < stream.end_time) {
    // @ts-ignore
    const elapsedTime = stream.cancelled.created_at.getTime() - stream.start_time.getTime();
    return elapsedTime / totalTime;
  }
  if (new Date() > stream.end_time) return 1;
  const elapsedTime = new Date().getTime() - stream.start_time.getTime();
  return elapsedTime / totalTime;
}

export function decimalToHex(d: number | string) {
  const hex = Number(d).toString(16);
  return hex.length % 2 === 1 ? "0" + hex : hex;
}

export function mapStreamApiResponse(apiResponse: IStreamApiResponse): IStream {
  const result: any = {
    ...apiResponse,
    start_time: new Date(apiResponse.start_time),
    end_time: new Date(apiResponse.end_time),
  };

  if (result?.cancelled) {
    result.cancelled.created_at = new Date(result.cancelled.created_at);
  }

  return result;
}

export const hexToBech32 = (address: string) => {
  return new Address(address).bech32();
};

export const bech32ToHex = (address: string) => {
  return new Address(address).hex();
};

export const denominate = (value: string | number, decimals: number) => {
  return new BigNumber(value).shiftedBy(-decimals).toNumber();
};
