import { configureStore } from "@reduxjs/toolkit";

import createStreamSlice from "./slices/createStreamSlice";
import notificationsSlice from "./slices/notificationsSlice";
import streamListSlice from "./slices/streamListSlice";

const store = configureStore({
  reducer: {
    notifications: notificationsSlice,
    createStreamPanel: createStreamSlice,
    streams: streamListSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
