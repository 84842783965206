import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { Logo } from './shared/Logo';

export enum NotificationType {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export interface INotificationProps {
  id: string;
  title: string;
  body: string;
  dismissible?: boolean;
  type?: NotificationType;
}

const getBgColor = (type: NotificationType | undefined) => {
  switch (type) {
    case NotificationType.SUCCESS:
      return "#22c55e";
    case NotificationType.WARNING:
      return "#facc15";
    case NotificationType.ERROR:
      return "#ef4444";
    default:
      return "#d1d5db";
  }
};

export default function Notification(
  { id, title, body, dismissible = true, type = NotificationType.INFO }: INotificationProps,
  dismissNotification: (id: string) => void
) {
  const color = getBgColor(type);

  return (
    <Transition
      key={id}
      show={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-secondary shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {/* <div className={classNames("w-4 h-4 mt-1 rounded-full", color)} /> */}
              <div className="w-5 pt-2">
                <Logo onlyIcon={true} className="h-5" fill={color} />
              </div>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-light-blue">{title}</p>
              <p className="mt-1 text-xs text-white opacity-50 break-all">
                <a
                  href={`${process.env.NEXT_PUBLIC_NETWORK_EXPLORER_ADDRESS}/transactions/${body}`}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  {body}
                </a>
              </p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              {dismissible && (
                <button
                  className="rounded-full w-4 h-4 inline-flex items-center justify-center text-white opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    dismissNotification(id);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <span className="">x</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
