import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStreamListingItem } from '../../types/Database';
import { getStreamsByAddress } from '../../utils/supabase';

const initialState: { items: Array<IStreamListingItem> } = {
  items: [],
};

export const fetchStreams = createAsyncThunk("streams/fetchStreams", async ({ address }: { address: string }) => {
  try {
    const data = await getStreamsByAddress(address);
    return data;
  } catch (e) {
    return [];
  }
});

const streamListSlice = createSlice({
  initialState,
  name: "streams",
  reducers: {
    addStream: (state, action: PayloadAction<IStreamListingItem>) => {
      state.items = [action.payload, ...state.items];
    },
    removeStream: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((e) => e.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStreams.fulfilled, (state, action) => {
      //@ts-ignore
      state.items = action.payload;
    });
  },
});

export const { addStream, removeStream } = streamListSlice.actions;
export default streamListSlice.reducer;
