import { Address } from '@multiversx/sdk-core/out';
import { createClient } from '@supabase/supabase-js';

import { appEnv } from '../config';
import { IStreamListingItem, IStreamResource } from '../types/Database';
import { bech32ToHex, denominate } from './presentation';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL as string,
  process.env.NEXT_PUBLIC_SUPABASE_PUBLIC as string
);

const DEVNET_PREFIX = "devnet_";
export const STREAMS_TABLE = "streams";
export const CLAIMS_TABLE = "stream_claims";
export const CANCEL_TABLE = "canceled_streams";

export const getTableName = (key: string) => {
  if (appEnv === "devnet") return DEVNET_PREFIX + key;
  return key;
};

export const mapStreamResponse = (e: IStreamResource): IStreamListingItem => {
  return {
    id: e.id,
    sender: new Address(e.sender).bech32(),
    recipient: new Address(e.recipient).bech32(),
    payment_token: e.payment_token,
    payment_token_label: e.payment_token_label,
    payment_amount: denominate(e.deposit, e.decimals),
    start_time: new Date(e.start_time),
    end_time: new Date(e.end_time),
    decimals: e.decimals,
    status: e.status,
  };
};

export const getStreamsByAddress = async (_address: string): Promise<IStreamListingItem[]> => {
  const address = bech32ToHex(_address);
  const { data, error } = await supabase
    .from(getTableName(STREAMS_TABLE))
    .select("*")
    .or(`sender.eq.${address},recipient.eq.${address}`)
    .eq("status", "active")
    .order("id", { ascending: false });
  if (error) return [];

  return data?.map(mapStreamResponse) || [];
};

export const getFinishedStreamsByAddress = async (_address: string): Promise<IStreamListingItem[]> => {
  const address = bech32ToHex(_address);
  const { data, error } = await supabase
    .from(getTableName(STREAMS_TABLE))
    .select("*")
    .or(`sender.eq.${address},recipient.eq.${address}`)
    .neq("status", "active")
    .order("id", { ascending: false });
  if (error) return [];

  return data?.map(mapStreamResponse) || [];
};
