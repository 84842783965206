import '../styles/globals.css';
import 'react-tooltip/dist/react-tooltip.css';

import { AuthContextProvider } from '@elrond-giants/erd-react-hooks';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import Notifications from '../components/Notifications';
import { appEnv, appName, walletConnectProjectId } from '../config';
import store from '../redux/store';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title={`${appName} - The protocol for real-time ESDT payments`}
        openGraph={{
          title: `${appName} - The protocol for real-time ESDT payments`,
          description:
            "We know how important is to get paid on time. With CoinDrip, on time means every second. As a user, you can see your tokens stream increase in real time. As a web3 company, we just made payment easier and more fair for you.",
          images: [
            {
              url: "https://devnet.coindrip.finance/og-image.jpg",
              width: 1200,
              height: 675,
              alt: appName,
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "@CoinDripHQ",
          cardType: "summary_large_image",
        }}
      />
      <ReduxProvider store={store}>
        <AuthContextProvider env={appEnv} projectId={walletConnectProjectId} enableWebview={true}>
          <Component {...pageProps} />
          <Notifications />
        </AuthContextProvider>
      </ReduxProvider>
    </>
  );
}

export default MyApp;
